import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Radio,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import CaretDown from 'app/components/icons/side-bar-icons/caret-down';
import { Link } from 'react-router-dom';
import {
  generateGMapsLinkCords,
  calculateDistance,
  getAccessToken,
  convertDateToFormattedString,
  getFormattedTime,
} from 'app/utils/common-utils/common-utils';
import CloseIcon from '@mui/icons-material/Close';
import { participateInActivity, participateInActivityCard } from 'app/shared/reducers/volunteer';
import JoiningSuccessModal from 'app/components/common/modals/joining-success-modal';
import ActivityContribute from 'app/components/ui/activity-details-section/activity-resource/activity-contribute';
import { isBefore, isEqual, differenceInMinutes, parseISO } from 'date-fns';
import { announceArrival } from 'app/shared/reducers/announce-arrival';
import toast from 'react-hot-toast';
import AllContributionsListModal from 'app/components/common/modals/all-contributions-list-modal/all-contributions-list-modal';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import RemoteEventIcon from 'app/components/icons/activity-card/remote-event';
import './recurring-details-side-panel.scss';
import StaticGMapImage from 'app/components/common/google-maps/static-g-map-image';
import { postingRulesType } from 'app/utils/types/activity/activity-types';
import Cookies from 'js-cookie';
import axios from 'axios';
import { getPhoneNumber, toggleModalLogin } from 'app/shared/reducers/authentication';
import RadioDialog from 'app/components/common/alert-dialog/radio-dialog';
import { fetchBanned } from 'app/shared/reducers/profile';
import InstructionsComponent from 'app/components/common/cards/activity-specific-instruction/activity-specific-instructions';
import { isRemoteChildActivity } from 'app/shared/reducers/bookmark-activity';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import PhoneVerification from '../../phone-verification/phone-verification';

interface Instruction {
  id: number;
  instruction: string;
}

const RecurringDetailsSidePanel = ({
  activityResponse,
  volunteerButtonClick,
  isSubmitClicked,
  onVolunteerButtonClick,
  onIsSubmitClicked,
  childrensResponse,
  onDataChange,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const mapDefaultLatValue = useAppSelector(state => state.mapCoordinates.latitude);
  const mapDefaultLngValue = useAppSelector(state => state.mapCoordinates.longitude);
  const [activityLocation, setactivityLocation] = useState<any>({});
  const [currentLocation, setcurrentLocation] = useState<any>({});
  const [isReached, setisReached] = useState<boolean>(false);
  const [openRemoteModal, setOpenRemoteModal] = useState<boolean>(false);
  const [isContributeOpen, setIsContributeOpen] = useState(false);
  const [viewContributions, setviewContributions] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [openModal, setOpenModal] = useState(false);
  const breakPoint = useMediaQuery('(max-width:988px)');
  const [volunteerAnchorEl, setVolunteerAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);

  let token;

  const [showOrgDialog, setShowOrgDialog] = useState(false);
  const handleOrgsDialog = value => {
    setShowOrgDialog(value);
  };

  const [currentActivityId, setCurrentActivityId] = useState(null);

  const getCurrentActivity = id => {
    setCurrentActivityId(id);
  };
  const clearCurrentActivity = () => {
    setCurrentActivityId(null);
  };

  const handleOptionChange = event => {
    const selectedValue = event.target.value;

    if (parseInt(selectedValue, 10) === 1) {
      setSelectedOrgId(1);
      setSelectedOption('My Self');
    } else {
      const selectedOrganization = userOwnedOrganizations.find(organization => organization.id === parseInt(selectedValue, 10));

      if (selectedOrganization) {
        setSelectedOption(selectedOrganization.label);
        setSelectedOrgId(selectedOrganization.id);
      }
    }
  };

  const handleInstructionsModal = value => {
    setIsVolunteerModalOpen(value);
  };

  const handleCancel = () => {
    setShowOrgDialog(false);
    setSelectedOrgId(null);
    setSelectedOption('');
  };

  const changeOrgId = value => {
    setSelectedOrgId(value);
  };

  const authTokenFromStorage = (async () => {
    try {
      const authToken = await getAccessToken();
      if (authToken) {
        return authToken;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  })();
  (async () => {
    token = await authTokenFromStorage;
  })();

  useEffect(() => {
    const latitude = activityResponse?.latitude;
    const longitude = activityResponse?.longitude;
    const activityLocation = {
      latitude: latitude,
      longitude: longitude,
    };
    setactivityLocation(activityLocation);
  }, [volunteerButtonClick, isSubmitClicked]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonVolunteeer, setIsButtonVolunteeer] = useState(false);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(400));
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(true);
  const [phoneVerificationModal, setPhoneVerificationModal] = useState<boolean>(false);
  const [otpVerificationCompleted, setOtpVerificationCompleted] = useState(false);
  const [phoneInputCompleted, setPhoneInputCompleted] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('getPhone') && localStorage.getItem('getPhone') !== undefined) {
      const phoneDataString = localStorage.getItem('getPhone');
      if (phoneDataString) {
        try {
          const phoneData = JSON.parse(phoneDataString);
          if (typeof phoneData.verified === 'boolean') {
            setPhoneVerificationStatus(phoneData.verified);
          } else {
            console.error('Verified status is missing or invalid in localStorage data');
          }
        } catch (error) {
          console.error('Failed to parse phone data from localStorage:', error);
        }
      } else {
        console.log('No phone data found in localStorage');
      }
    }
  }, [localStorage.getItem('getPhone')]);

  const handlePhoneVerificationClose = () => {
    setPhoneVerificationModal(false);
    if (otpVerificationCompleted) {
      fetchPhoneNumber();
    } else {
      setPhoneInputCompleted(false);
    }
  };

  const fetchPhoneNumber = async () => {
    try {
      const response = await dispatch(getPhoneNumber());
      if (response.meta.requestStatus == 'fulfilled') {
        localStorage.setItem('getPhone', JSON.stringify(response.payload));
        setPhoneVerificationStatus(response.payload?.verified);
      } else {
        // toast.error('Unable to fetch verification Status', { id: 'phone-verification-status-error' });
      }
    } catch (error) { }
  };

  const openVolunteerModal = () => {
    if (isAuthenticated) {
      console.log('inside');
      setIsButtonVolunteeer(true);
      setIsVolunteerModalOpen(true);
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handleVolunteerReccuringAs = async () => {
    if (isAuthenticated) {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }

      if (phoneVerificationStatus) {
        if (userOwnedOrganizations?.length > 0) {
          console.log("first")
          if (selectedOption) {
            console.log("second")
            // openVolunteerModal();
            handleOrgsDialog(true);
          } else {
            console.log("third")
            handleOrgsDialog(true);
            
          }
        } else {
          console.log("fourth")
          handleOrgsDialog(true);
          changeOrgId(1);
          // openVolunteerModal();
        }
      } else {
        setPhoneVerificationModal(true);
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };
  const [selectedVolunteerOptions, setSelectedVolunteerOptions] = useState<any[]>([]);
  const [volunteerActivityAnchorEl, setVolunteerActivityAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openVolunteer = Boolean(volunteerActivityAnchorEl);
  const volunteerActivityId = openVolunteer ? 'volunteer-options-popover' : undefined;
  const handleVolunteerAs = (id: any, event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedVolunteerOptions([]);
    if (isAuthenticated) {
      setVolunteerActivityAnchorEl(event.currentTarget);
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };
  const handleOkVolunteerClick = () => {
    if (isAuthenticated) {

      const parent = childrensResponse.find(item => item.parent);
      if (selectedVolunteerOptions?.length > 1 && parent && selectedVolunteerOptions.includes(parent.id)) {
        setSelectedVolunteerOptions([parent.id]);
      }
        handleVolunteerReccuringAs();
      handleVolunteerOptionsClose();
    }
  };
  const handleVolunteerOptionsClose = () => {
    setVolunteerActivityAnchorEl(null);
  };
  const handleOptionVolunteerClick = (option: any) => {
    if (option?.parent) {
      if (selectedVolunteerOptions.includes(option?.id)) {
        setSelectedVolunteerOptions([]);
      } else {
        const allIds = childrensResponse?.map(child => child.id);
        setSelectedVolunteerOptions([...allIds, option?.id]);
      }
    } else {
      const selectedId = option?.id;
      const parent = childrensResponse.find(item => item.parent);
      const childIds = childrensResponse.filter(item => !item.parent).map(child => child.id);
      if (selectedVolunteerOptions.includes(parent?.id)) {
        setSelectedVolunteerOptions(selectedVolunteerOptions.filter(id => id !== parent?.id));
      }
      let updatedSelection = selectedVolunteerOptions.includes(selectedId)
        ? selectedVolunteerOptions.filter(id => id !== selectedId)
        : [...selectedVolunteerOptions, selectedId];
      const areAllChildrenSelected = childIds.every(id => updatedSelection.includes(id));
      if (areAllChildrenSelected) {
        updatedSelection = [...updatedSelection, parent?.id];
      } else {
        updatedSelection = updatedSelection.filter(id => id !== parent?.id);
      }

      setSelectedVolunteerOptions(updatedSelection);
    }
  };

  const errors = err => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  // check if user's location is within 100 meters of event location
  const isWithin100Meters = (userLocation, eventLocation) => {
    const distance = calculateDistance(userLocation, eventLocation);
    return distance < 100;
  };

  // Check if today is the activity start day
  const isCurrentDateEqualToEventDate = startDate => {
    const currentDate = new Date();
    const eventDate = new Date(startDate);

    currentDate.setHours(0, 0, 0, 0);
    eventDate.setHours(0, 0, 0, 0);

    return isEqual(currentDate, eventDate);
  };

  const isCurrentTimeWithin30MinutesBeforeActivityStart = activityStartTime => {
    const currentDateTime = new Date();
    const eventDateTime = parseISO(activityStartTime);
    if (isBefore(currentDateTime, eventDateTime)) {
      const timeDifferenceMinutes = differenceInMinutes(eventDateTime, currentDateTime);
      return timeDifferenceMinutes <= 30;
    }
    return false;
  };

  const isAnnounceArrivalButtonVisible = (activityDate, activityTime) => {
    if (isCurrentDateEqualToEventDate(activityDate) && isCurrentTimeWithin30MinutesBeforeActivityStart(activityTime)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAnnounceArrival = async () => {
    let currentLocation;
    try {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            currentLocation = {
              latitude: latitude,
              longitude: longitude,
            };
            setcurrentLocation(currentLocation);
            // announce arrival
            const response = await dispatch(
              announceArrival({
                activityId: activityResponse?.id,
                latitude: currentLocation.latitude,
                longitude: currentLocation.longitude,
              })
            );
            if (response.meta.requestStatus == 'fulfilled') {
              toast.success(response.payload, { id: 'announce-arrival-success' });
              setisReached(true);
            } else {
              toast.error(response.payload, { id: 'announce-arrival-error' });
            }
          },
          function (error) {
            if (error.code === error.PERMISSION_DENIED) {
              alert('Location access denied. Please enable location services.');
            } else {
              alert('Error getting location: ' + error.message);
            }
          }
        );
      } else {
        alert('Geolocation is not supported in this browser.');
      }
    } catch (error) {
      toast.error('something went wrong!!', { id: 'announce-arrival-something-wrong' });
      console.error('API error:', error);
    }
  };

  const [remote, setRemote] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      if (activityResponse?.id) {
        try {
          const res = await dispatch(isRemoteChildActivity({ id: activityResponse?.id, recurringId: 0 }));
          setRemote(res.payload);
        } catch (error) {
          console.error('Error fetching remote activity:', error);
        }
      }
    };

    fetchData();
  }, [activityResponse?.id]);

  const handleMapClick = () => {
    if (isAuthenticated) {
      if (activityResponse?.online && activityResponse?.participant && (remote || activityResponse?.hasPermission)) {
        // go to the meeting link
        setOpenRemoteModal(s => !s);
      } else if (!activityResponse?.online) {
        // go to google map for near by place using lat and longitude
        const mapLink = generateGMapsLinkCords(activityResponse?.latitude, activityResponse?.longitude);
        window.open(mapLink, '_blank');
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handleRemoteActivityClose = () => {
    setOpenRemoteModal(false);
  };

  const joinAndProceed = async () => {
    setIsButtonClicked(true);
    localStorage.removeItem('respectiveActivityId');
    try {
      let response;
      if (selectedOrgId === null || selectedOrgId === 1) {
         response = await dispatch(participateInActivityCard({ activityIds: selectedVolunteerOptions, participantType: selectedParticipantOption }));
      } else {
        response = await dispatch(participateInActivityCard({ activityIds: selectedVolunteerOptions, organizationId: selectedOrgId, participantType: selectedParticipantOption }));
      }
      if (response.meta.requestStatus == 'fulfilled') {
        handleParticipantPopoverClose();
        setSelectedOrgId(null);
        setSelectedOption('');
        setShowOrgDialog(false);
        onVolunteerButtonClick(true);
        setIsVolunteerModalOpen(false);
        setIsButtonClicked(false);
        setOpenModal(true);
        onDataChange(activityResponse?.id);
      } else {
        setIsVolunteerModalOpen(false);
        setSelectedParticipantOption(null)
      }
    } catch (error) {
      console.error('Error joining the activity:', error);
    }
  };

  const handleResource = () => {
    setIsContributeOpen(true);
  };

  const handleResourceClose = () => {
    setIsContributeOpen(false);
  };

  const handleContributionsViewModal = () => {
    setviewContributions(true);
  };

  const handleContributionsViewModalClose = () => {
    setviewContributions(false);
  };

  const [toolTipText, setToolTipText] = useState<string | null>(null);

  useEffect(() => {
    const updatedToolTipText = !isAuthenticated
      ? null
      : isAuthenticated &&
        !activityResponse?.participant &&
        activityResponse?.activityStatus !== 'ENDED' &&
        activityResponse?.activityStatus !== 'COMPLETED'
        ? 'Be a volunteer or attendee to access the link'
        : !activityResponse?.hasPermission && activityResponse?.participant && !remote && activityResponse?.activityStatus === 'OPEN'
          ? 'Link will be published 30 mins before the activity starts'
          : activityResponse?.activityStatus === 'ENDED' || activityResponse?.activityStatus === 'COMPLETED'
            ? 'This activity has ended.'
            : null;

    setToolTipText(updatedToolTipText);
  }, [
    activityResponse?.hasPermission,
    activityResponse?.activityStatus,
    isAuthenticated,
    activityResponse?.id,
    remote,
    activityResponse?.participant,
  ]);

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonText = isSmallDevice
    ? (activityResponse?.participantOption === "VOLUNTEER_ONLY" ? 'Volunteer' : 'Attendee')
    : (activityResponse?.participantOption === "VOLUNTEER_ONLY" ? 'I want to volunteer' : 'I want to attend');
  const [selectedParticipantOption, setSelectedParticipantOption] = useState<string | null>(null);
  const [participantAnchorEl, setParticipantAnchorEl] = useState<null | HTMLElement>(null);

  const handleParticipantPopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setParticipantAnchorEl(event.currentTarget);
  };

  const handleParticipantPopoverClose = () => {
    setParticipantAnchorEl(null);
  };

  const isParticipantPopoverOpen = Boolean(participantAnchorEl);
  const [activityPostingRules, setActivityPostingRules] = useState<postingRulesType[]>([]);
  useEffect(() => {
    const fetchPostingRules = async () => {
      try {
        if(isAuthenticated){
          const response = await axios.get<postingRulesType[]>('api/volunteer_instructions');

          setActivityPostingRules(response.data);
        }
       
      } catch (error) {
        console.log(error);
      }
    };

    fetchPostingRules();
  }, [activityResponse]);

  const VolunteerModal = (
    <Grid>
      {activityResponse && Object.keys(activityResponse).length > 0 && (
        <Grid>
          <Dialog
            open={isVolunteerModalOpen}
            onClose={() => {
              setIsVolunteerModalOpen(false);
              handleCancel();
            }}
            PaperProps={{
              sx: {
                borderRadius: '15px',
                padding: '2%',
              },
            }}
          >
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <CloseIcon
                onClick={() => {
                  setIsVolunteerModalOpen(false);
                  handleCancel();
                }}
                sx={{ cursor: 'pointer' }}
              />
            </Stack>

            <DialogContent sx={{ height: 'auto', overflowY: 'hidden' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activityPostingRules && activityPostingRules?.length > 0 ? (
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      {selectedParticipantOption !== 'ATTENDEE'
                        ? 'Instructions for volunteers'
                        : 'Instructions for attendees'}
                    </Typography>
                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        p: 2,
                        pt: 0,
                        height: '200px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: 'scroll',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                          display: 'block',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activityPostingRules?.length
                        ? activityPostingRules?.map(item => (
                          <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                            <Stack p={0.7}>
                              <Box
                                sx={{
                                  width: '9px',
                                  height: '9px',
                                  borderRadius: '50%',
                                  backgroundColor: '#775FFF',
                                }}
                              />
                            </Stack>

                            <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                              <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                            </Stack>
                          </Stack>
                        ))
                        : null}
                    </Paper>
                  </Grid>
                ) : null}
              </div>

              <InstructionsComponent passedData={activityResponse} />
            </DialogContent>

            <DialogActions
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Stack
                direction={'column'}
                gap={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => joinAndProceed()}
                  sx={{
                    background: theme.palette.primary.main,
                    color: '#292D34',
                    borderRadius: '15px',
                    width: '159px',
                    height: '40px',
                    '&:hover': {
                      background: theme.palette.primary.main,
                      color: '#292D34',
                      borderRadius: '15px',
                      width: '159px',
                      height: '40px',
                    },
                  }}
                >
                  Join & proceed
                </Button>

                <Typography sx={{ fontSize: '14px', color: theme.textColor.heading }}>
                  By joining this event you accept the rules/instructions of this event.
                </Typography>
              </Stack>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );

  useEffect(() => {
    const allResourcesEmpty = activityResponse?.resources?.filter(item => item.filled > 0);
    allResourcesEmpty?.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
  }, [activityResponse]);

  const handleRemote = async () => {
    const body = {
      activityId: activityResponse?.id,
    };
    const response = await axios.post<any>(`/api/activity/announce-remote-arrival`, body);
  };
  return (
    <div>
      <RadioDialog
        selectedOrgId={selectedOrgId}
        open={showOrgDialog}
        handleShow={handleOrgsDialog}
        handleOptionChange={handleOptionChange}
        handleCancel={handleCancel}
        handleInstructionsModal={handleInstructionsModal}
        title={selectedParticipantOption === 'ATTENDEE' ? "Attending as" : "Volunteering as"}
      />
      {activityResponse && Object.keys(activityResponse).length > 0 && (
        <Grid container padding={0} gap={0}>
          <Grid
            container
            item
            alignItems={'center'}
            justifyContent={'center'}
            width="100%"
            sx={{
              height: activityResponse?.online ? '100px' : '164px',
              cursor: 'pointer',
              display: breakPoint && activityResponse?.online ? 'none' : 'block',
              m: 1,
            }}
            onClick={handleMapClick}
          >
            {activityResponse?.online ? (
              <Tooltip title={toolTipText}>
                <Stack
                  sx={{
                    m: 5,
                    textAlign: 'center',
                    direction: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    display: breakPoint ? 'none' : 'block',
                  }}
                >
                  <RemoteEventIcon sx={{ fontSize: '30px' }} />
                  <br />
                  <small
                    style={{
                      font: 'normal normal normal 14px/23px Manrope',
                      color: isDarkMode ? '#BFBFBF' : '#5A5F67',
                      textAlign: 'center',
                    }}
                  >
                    Remote event
                  </small>
                </Stack>
              </Tooltip>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StaticGMapImage
                  lat={activityResponse?.latitude || mapDefaultLatValue}
                  lng={activityResponse?.longitude || mapDefaultLngValue}
                />
              </Box>
            )}
          </Grid>
          {activityResponse?.participantOption !== "BOTH" && !activityResponse?.participant && activityResponse.activityStatus == 'OPEN' && !activityResponse?.makeThisPrivateActivity && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Tooltip
                title={
                  activityResponse?.attendeeFilled
                    ? 'Attendee capacity is full'
                    : activityResponse?.volunteerFilled
                      ? 'Volunteer capacity is full'
                      : ''
                }

                arrow
              >
                <span>
                  <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600', textAlign: 'center' }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (activityResponse?.participantOption === "VOLUNTEER_ONLY") {
                        setSelectedParticipantOption("VOLUNTEER");
                      } else if (activityResponse?.participantOption === "ATTENDEE_ONLY") {
                        setSelectedParticipantOption("ATTENDEE");
                      }
                      handleVolunteerAs(activityResponse.id, event);
                    }}
                    disabled={activityResponse?.attendeeFilled || activityResponse?.volunteerFilled}
                  >
                    {buttonText}
                  </Button>
                </span>
              </Tooltip>

            </Grid>
          )}
          {activityResponse?.participantOption !== "BOTH" && !activityResponse?.participant &&
            activityResponse.activityStatus == 'OPEN' &&
            activityResponse?.makeThisPrivateActivity &&
            activityResponse?.invited && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Tooltip
                  title={
                    activityResponse?.attendeeFilled
                      ? 'Attendee capacity is full'
                      : activityResponse?.volunteerFilled
                        ? 'Volunteer capacity is full'
                        : ''
                  }

                  arrow
                >
                  <span>
                    <Button
                      variant="contained"
                      style={{ textTransform: 'none' }}
                      sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600', textAlign: 'center' }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (activityResponse?.participantOption === "VOLUNTEER_ONLY") {
                          setSelectedParticipantOption("VOLUNTEER");
                        } else if (activityResponse?.participantOption === "ATTENDEE_ONLY") {
                          setSelectedParticipantOption("ATTENDEE");
                        }
                        handleVolunteerAs(activityResponse.id, event);
                      }}
                      disabled={activityResponse?.attendeeFilled || activityResponse?.volunteerFilled}
                    >
                      {buttonText}
                    </Button>
                  </span>
                </Tooltip>

              </Grid>
            )}

          {activityResponse?.participantOption === "BOTH" && !activityResponse?.participant &&
            activityResponse.activityStatus == 'OPEN' &&
            activityResponse?.makeThisPrivateActivity &&
            activityResponse?.invited && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600', textAlign: 'center' }}
                  onClick={handleParticipantPopoverOpen}
                >
                  I want to join
                </Button>
              </Grid>
            )}

          {activityResponse?.participantOption === "BOTH" && !activityResponse?.participant && activityResponse.activityStatus == 'OPEN' && !activityResponse?.makeThisPrivateActivity && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Button
                variant="contained"
                style={{ textTransform: 'none' }}
                sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600', textAlign: 'center' }}
                onClick={handleParticipantPopoverOpen}
              >
                I want to join
              </Button>
            </Grid>
          )}
          {activityResponse?.participant && !activityResponse?.attendee && !activityResponse?.hasPermission && activityResponse.activityStatus == 'OPEN' && (
            <Grid className="m-3" item container justifyContent={'center'} alignItems={'center'}>
              <Button
                variant="text"
                style={{ textTransform: 'none', cursor: 'default' }}
                sx={{
                  borderRadius: '15px',
                  fontSize: '14px',
                  color: isDarkMode ? '#FFCE00' : '#674BFF',
                  fontWeight: '600',
                  '&:hover': {
                    backgroundColor: 'none',
                  },
                }}
              >
                Thanks for volunteering
              </Button>
            </Grid>
          )}
          {activityResponse?.participant && activityResponse?.attendee && !activityResponse?.hasPermission && activityResponse.activityStatus == 'OPEN' && (
            <Grid className="m-3" item container justifyContent={'center'} alignItems={'center'}>
              <Button
                variant="text"
                style={{ textTransform: 'none', cursor: 'default' }}
                sx={{
                  color: isDarkMode ? '#FFCE00' : '#674BFF',
                  borderRadius: '15px',
                  fontSize: '14px',
                  fontWeight: '600',
                  '&:hover': {
                    backgroundColor: 'none',
                  },
                }}
              >
                Thanks for attending
              </Button>
            </Grid>
          )}
          {!activityResponse?.hasPermission &&
            !activityResponse?.invited &&
            activityResponse?.makeThisPrivateActivity &&
            !activityResponse?.participant &&
            activityResponse?.activityStatus == 'OPEN' && (
              <Button
                variant="text"
                className="me-1"
                style={{ cursor: 'default' }}
                sx={{
                  color: isDarkMode ? '#FFCE00' : '#674BFF',
                  fontSize: '14px',
                  borderRadius: '20px',
                  height: '28px',
                  lineHeight: '12px',
                  '&:hover': {
                    backgroundColor: 'none',
                  },
                }}
              >
                By invite only
              </Button>
            )}

          {activityResponse?.activityStatus === 'COMPLETED' && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Button
                variant="text"
                sx={{
                  fontSize: '14px',
                  borderRadius: '20px',
                  height: '28px',
                  lineHeight: '12px',
                  cursor: 'auto',
                  color: isDarkMode ? '#FFCE00' : '#674BFF',
                  '&:hover': {
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                  },
                }}
              >
                Activity completed
              </Button>
            </Grid>
          )}

          {activityResponse?.activityStatus === 'RUNNING' && activityResponse?.category?.id !== 19 && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Button
                variant="text"
                sx={{
                  fontSize: '14px',
                  borderRadius: '20px',
                  height: '28px',
                  lineHeight: '12px',
                  cursor: 'auto',
                  color: isDarkMode ? '#FFCE00' : '#674BFF',
                  '&:hover': {
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                  },
                }}
              >
                Activity started
              </Button>
            </Grid>
          )}

          {activityResponse?.activityStatus === 'ENDED' &&
            activityResponse?.participant &&
            activityResponse?.reached &&
            activityResponse?.starRating < 4 &&
            activityResponse?.starRating !== null && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="text"
                  style={{
                    cursor: 'default',
                  }}
                  sx={{
                    fontSize: '14px',
                    borderRadius: '20px',
                    height: '28px',
                    textWrap: 'nowrap',
                    lineHeight: '12px',
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                    '&:hover': {
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                    },
                  }}
                >
                  Pending feedback
                </Button>
              </Grid>
            )}
          {activityResponse?.activityStatus === 'ENDED' &&
            !activityResponse?.reached &&
            !activityResponse?.hasPermission &&
            (activityResponse?.starRating === 0 || activityResponse?.starRating === null || activityResponse?.starRating < 4) && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="text"
                  style={{
                    cursor: 'default',
                  }}
                  sx={{
                    fontSize: '14px',
                    borderRadius: '20px',
                    height: '28px',
                    textWrap: 'nowrap',
                    lineHeight: '12px',
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                    '&:hover': {
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                    },
                  }}
                >
                  Pending feedback
                </Button>
              </Grid>
            )}

          {activityResponse?.activityStatus === 'ENDED' &&
            activityResponse?.hasPermission &&
            (activityResponse?.starRating === 0 || activityResponse?.starRating === null || activityResponse?.starRating < 4) && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="text"
                  style={{
                    cursor: 'default',
                  }}
                  sx={{
                    fontSize: '14px',
                    borderRadius: '20px',
                    height: '28px',
                    textWrap: 'nowrap',
                    lineHeight: '12px',
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                    '&:hover': {
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                    },
                  }}
                >
                  Pending feedback
                </Button>
              </Grid>
            )}

          {activityResponse?.starRating > 3.9 && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Tooltip title="Highly Rated" arrow>
                <MilitaryTechIcon color="primary" sx={{ width: '24px', height: '24px', fontSize: '16px' }} />
              </Tooltip>
            </Grid>
          )}

          {activityResponse?.participant && activityResponse?.hasPermission && activityResponse?.activityStatus == 'OPEN' && (
            <Grid className="m-3" item container justifyContent={'center'} alignItems={'center'}>
              <Button
                variant="text"
                className="me-1"
                style={{ cursor: 'default' }}
                sx={{
                  color: isDarkMode ? '#FFCE00' : '#674BFF',
                  fontSize: '14px',
                  borderRadius: '20px',
                  height: '28px',
                  lineHeight: '12px',
                  '&:hover': {
                    backgroundColor: 'none',
                  },
                }}
              >
                Thanks for organizing
              </Button>
            </Grid>
          )}

          <Popover
            open={isParticipantPopoverOpen}
            anchorEl={participantAnchorEl}
            onClose={handleParticipantPopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ padding: 2, width: '200px' }}>
              <Typography variant="h6">Join as</Typography>
              <Stack direction="column" spacing={2} mt={2}>
                <Tooltip
                  title={activityResponse?.attendeeFilled ? 'Attendee capacity is full' : ''}
                  arrow
                  disableHoverListener={!activityResponse?.attendeeFilled}
                >
                  <span>
                    <FormControlLabel
                      control={
                        <Radio
                          sx={{
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                            '&.Mui-checked': {
                              color: isDarkMode ? '#FFCE00' : '#674BFF',
                            },
                          }}
                          checked={selectedParticipantOption === 'ATTENDEE'}
                          onChange={() => {
                            setSelectedParticipantOption('ATTENDEE');

                          }}
                          disabled={activityResponse?.attendeeFilled}
                        />
                      }
                      label="Attendee"
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  title={activityResponse?.volunteerFilled ? 'Volunteer capacity is full' : ''}
                  arrow
                  disableHoverListener={!activityResponse?.volunteerFilled}
                >
                  <span>
                    <FormControlLabel
                      control={
                        <Radio
                          sx={{
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                            '&.Mui-checked': {
                              color: isDarkMode ? '#FFCE00' : '#674BFF',
                            },
                          }}
                          checked={selectedParticipantOption === 'VOLUNTEER'}
                          onChange={() => {
                            setSelectedParticipantOption('VOLUNTEER');

                          }}
                          disabled={activityResponse?.volunteerFilled}
                        />
                      }
                      label="Volunteer"
                    />
                  </span>
                </Tooltip>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
              <Button
                  sx={{
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                  }}
                  onClick={handleParticipantPopoverClose}>Cancel</Button>
                <Button
                   sx={{
                    backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
                    color: isDarkMode ? 'black' : '#ffff',
                    '&:hover': {
                      backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
                    },
                  }}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    // handleParticipantPopoverClose();
                    handleVolunteerAs(activityResponse.id, event);
                  }}
                  disabled={!selectedParticipantOption}
                  variant="contained"
                >
                  Proceed
                </Button>
               
              </Stack>
            </Box>
          </Popover>
          <Popover
            id={volunteerActivityId}
            open={openVolunteer}
            anchorEl={volunteerActivityAnchorEl}
            onClose={handleVolunteerOptionsClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box
              sx={{
                maxHeight: '150px',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                '-ms-overflow-style': 'none',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                },
              }}
            >
              <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit' }}>
                {/* Render options with checkboxes */}
                {childrensResponse?.map(option => {
                  const datePart = convertDateToFormattedString(option?.startDate);
                  const timePart1 = getFormattedTime(option?.startTime);
                  const timePart2 = getFormattedTime(option?.endTime);

                  return (
                    <ListItemButton key={option?.id} onClick={() => handleOptionVolunteerClick(option)}>
                      <ListItemIcon>
                        <Checkbox checked={selectedVolunteerOptions.includes(option?.id)} />
                      </ListItemIcon>

                      {!option?.parent ? (
                        <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                          <span style={{ fontWeight: 'bold' }}>{datePart}</span>
                          <br />
                          {timePart1}-{timePart2}
                        </Typography>
                      ) : (
                        <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                          <span style={{ fontWeight: 'bold' }}>{'Select all'}</span>
                        </Typography>
                      )}
                    </ListItemButton>
                  );
                })}
                {/* Add "OK" button */}
              </ListItem>
            </Box>
            <div style={{ display: 'flex', padding: '10px' }}>
              <ListItemButton
                onClick={handleOkVolunteerClick}
                disabled={selectedVolunteerOptions?.length === 0}
                sx={{
                  backgroundColor: '#FFCE00',
                  color: 'black',
                  borderRadius: '15px',
                  '&:hover': {
                    backgroundColor: '#FFCE00',
                  },
                }}
              >
                <ListItemText sx={{ p: 0, cursor: 'pointer', fontSize: '14px', textAlign: 'center' }}>Submit</ListItemText>
              </ListItemButton>
            </div>
          </Popover>
          {selectedOrgId && isVolunteerModalOpen && VolunteerModal}
          <Grid item container>
            <Accordion
              sx={{
                width: '100%',
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px -2px 1px -1px rgba(0,0,0,0.2)',
                background: isDarkMode ? '#2C2C38' : '#FFFFFF',
              }}
            >
              <AccordionSummary
                expandIcon={<CaretDown sx={{ height: 'unset', width: 'unset' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    color: theme.textColor.mainHeading,
                    fontSize: theme.p1.semiBold.fontSize,
                    fontWeight: theme.p1.semiBold.fontWeight,
                  }}
                >
                  Instructions
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ background: isDarkMode ? '#2C2C38' : '#FFFFFF', pt: 0 }}>
                <Paper
                  elevation={3}
                  sx={{
                    background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                    borderRadius: isEmpty ? 0 : '16px',
                    height: '105px',
                    width: '100%',
                    overflowY: activityResponse?.instructions && activityResponse?.instructions?.length > 2 ? 'scroll' : 'hidden',
                    overflowX: 'hidden',
                    boxShadow: 'none',
                    scrollbarWidth: 'auto',
                    '&::-webkit-scrollbar': {
                      display: 'block',
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: isDarkMode ? '#2C2C38' : '#f0f0f0',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#AAA',
                      borderRadius: '16px',
                    },
                  }}
                >
                  {activityResponse?.instructions?.length ? (
                    [
                      ...new Map(
                        (activityResponse.instructions as { id: number; instruction: string }[]).map(item => [item.instruction, item])
                      ).values(),
                    ].map(item => (
                      <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                        <Stack p={0.7}>
                          <Box
                            sx={{
                              width: '9px',
                              height: '9px',
                              borderRadius: '50%',
                              backgroundColor: '#775FFF',
                            }}
                          />
                        </Stack>
                        <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                          <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.instruction}</Typography>
                        </Stack>
                      </Stack>
                    ))
                  ) : (
                    <Typography>Organizer has not listed any instructions.</Typography>
                  )}
                </Paper>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {activityResponse?.lostPet === null && (
            <Grid sx={{ width: ' -webkit-fill-available' }}>
              <Grid padding={2}>
                <Typography
                  sx={{
                    color: theme.textColor.mainHeading,
                    fontSize: theme.p1.semiBold.fontSize,
                    fontWeight: theme.p1.semiBold.fontWeight,
                  }}
                >
                  Resources needed
                </Typography>

                {!isEmpty && (
                  <Typography
                    sx={{
                      color: theme.palette.secondary.light,
                      fontSize: theme.infoText.regular.fontSize,
                      fontWeight: theme.infoText.regular.fontWeight,
                      paddingBottom: '12px',
                    }}
                  >
                    Please add your contributions that you are willing to bring
                  </Typography>
                )}
                {!isEmpty && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link 
                      id="RouterNavLink"
                      style={{ paddingBottom: '12px', fontSize: '14px', fontWeight: '600', color: '#674bff', textDecoration: 'none' }}
                      onClick={() => handleContributionsViewModal()}
                      to={''}
                    >
                      View contributors
                    </Link>
                  </div>
                )}
                {activityResponse?.resources.length === 0 && (
                  <Typography sx={{ textAlign: 'left', pt: 2.5 }}>Organizer has not listed any resources.</Typography>
                )}
                <Paper
                  elevation={3}
                  sx={{
                    background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                    width: 'auto',

                    borderRadius: '16px',
                    p: 1,
                    pl: 2,
                    height: '120px',
                    overflowY: activityResponse?.resources && activityResponse?.resources?.length > 2 ? 'scroll' : 'hidden',
                    overflowX: 'hidden',
                    boxShadow: 'none',
                    scrollbarWidth: 'auto',
                    '&::-webkit-scrollbar': {
                      display: 'block',
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: isDarkMode ? '#2C2C38' : '#f0f0f0',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#AAA',
                      borderRadius: '16px',
                    },
                  }}
                >
                  <ol style={{ paddingLeft: '20px', height: '100px' }}>
                    {activityResponse?.resources?.length
                      ? activityResponse?.resources.map((resource, index) => (
                        <li key={index} className="pb-2">
                          <Typography
                            sx={{
                              color: theme.textColor.mainHeading,
                              fontSize: theme.p1.regular.fontSize,
                              fontWeight: theme.p1.regular.fontWeight,
                            }}
                          >
                            {resource.resources}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: theme.infoText.bold.fontSize,
                              fontWeight: theme.infoText.bold.fontWeight,
                              color: resource.filled >= resource.totalCount ? ' #00B158' : '#FF4B8E',
                            }}
                          >
                            {resource.filled >= resource.totalCount
                              ? `${resource.filled}/${resource.totalCount} Filled `
                              : `${resource.filled}/${resource.totalCount} Filled `}
                          </Typography>
                        </li>
                      ))
                      : null}
                  </ol>
                </Paper>
              </Grid>
            </Grid>
          )}

          {activityResponse?.resources?.length ? (
            <Grid className="mt-3" item container justifyContent={'center'} alignItems={'center'} gap={2} marginBottom={5}>
              {activityResponse?.participant &&
                (activityResponse?.activityStatus === 'OPEN' || activityResponse?.activityStatus === 'RUNNING') && (
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: '15px',
                      fontSize: '14px',
                      fontWeight: '600',
                      color: isDarkMode ? '#ffce00' : '#674bff',
                      borderColor: isDarkMode ? '#ffce00' : '#674bff',
                      '&:hover': {
                        color: isDarkMode ? '#ffce00' : '#674bff',
                        borderColor: isDarkMode ? '#ffce00' : '#674bff',
                        bgcolor: 'unset',
                      },
                    }}
                    onClick={() => handleResource()}
                    disabled={activityResponse?.resources?.length === 0 || activityResponse?.attendee}
                  >
                    {activityResponse?.attendee ? 'Attendees are unable to contribute' : 'Contribute'}
                  </Button>
                )}
            </Grid>
          ) : null}

          <ActivityContribute
            open={isContributeOpen}
            handleClose={handleResourceClose}
            handleData={activityResponse}
            onPostClick={() => onIsSubmitClicked(true)}
          />

          <AllContributionsListModal open={viewContributions} handleClose={handleContributionsViewModalClose} activity={activityResponse} />

          {/* login modal */}
          <AuthorisationModal
            currentComponent={currentComponent}
            fullScreen={fullScreen}
            authModalOpen={authModalOpen}
            onClose={handleAuthModalClose}
            handleAuthModalClose={handleAuthModalClose}
            setCurrentComponent={setCurrentComponent}
          />
          <JoiningSuccessModal
            handleClose={handleModalClose}
            fullScreen={fullScreen}
            open={openModal}
            url={'chat url'}
            title={
              selectedParticipantOption === 'VOLUNTEER'
                ? 'Thank you for volunteering.'
                : 'Thank you for attending.'
            }
          />
          <Dialog
            fullScreen={fullScreen}
            open={openRemoteModal}
            onClose={handleRemoteActivityClose}
            aria-labelledby="remote-activity-modal"
            PaperProps={{
              sx: {
                borderRadius: { xs: 0, sm: '14px' },
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                width: { lg: 400 },
              },
            }}
          >
            <Stack direction="column" sx={{ p: 2 }} spacing={3} minWidth={400} minHeight={200}>
              <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                <CloseIcon onClick={handleRemoteActivityClose} sx={{ cursor: 'pointer' }} />
              </Stack>
              <Typography variant="h4" textAlign={'center'}>
                Meeting Link
              </Typography>
              <Button
                variant="outlined"
                sx={{ borderColor: 'primary.light', py: 1, borderRadius: '20px', '&:hover': { borderColor: 'primary.light' } }}
              >
                <Link id="RouterNavLink" to={activityResponse?.onlineUrl} target="_blank" rel="noopener noreferrer" onClick={handleRemote}>
                  <Stack direction="column" alignItems={'center'}>
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        font: 'normal normal normal 14px/23px Manrope',
                        color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                      }}
                    >
                      {activityResponse?.onlineUrl}
                    </Typography>
                  </Stack>
                </Link>
              </Button>
            </Stack>
          </Dialog>
        </Grid>
      )}
      <PhoneVerification
        handleClose={handlePhoneVerificationClose}
        open={phoneVerificationModal}
        fullScreen={isSmallerScreen}
        phoneInputCompleted={phoneInputCompleted}
        setPhoneInputCompleted={setPhoneInputCompleted}
        otpVerificationCompleted={otpVerificationCompleted}
        setotpVerificationCompleted={setOtpVerificationCompleted}
      />
    </div>
  );
};
export default RecurringDetailsSidePanel;
